body {
  background: #FFF1C7;
  height: 100%;
  margin-top: 90px;
}

.App {
  font-family: sans-serif;
  text-align: center;
}


.header{

position: absolute;
width: 1440px;
height: 90px;
left: 0px;
top: 0px;
}


.header-text{
margin-left: 10px;
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
display: flex;
align-items: center;
text-align: center;

color: #483700;
}

.logo-image{
  width: 80px;
  margin-right: -20px;
}

.logo{

position: absolute;
width: 109px;
height: 36px;
left: 41px;
top: 18px;

font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 36px;
display: flex;
align-items: center;
text-align: center;
cursor: pointer;
text-decoration: none;


color: #483700;


}



.App-logo {
  height: 4vmin;
  pointer-events: none;
  margin-right: 50px;
}

@font-face {
font-family: "Font";
src: url("/src/styles/font.woff") format("woff");
}

.title {
  font-size: 70px;
  color: #483700;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 20px;
  margin-left: 100px;
  margin-top: 300px;
  font-weight: 800;
  margin-right: 400px;
}

.subtitle {
  font-size: 3vmin;
  color: #483700;
  font-weight: lighter;
  text-align: "left";
}

.paragraph {
  font-size: 15px;
  color: #483700;
  font-weight: bold;
}

.video-container {
  /* Frame 4 */

box-sizing: border-box;

position: absolute;
width: 503px;
height: 453px;
left: 805px;
top: 201px;

border:transparent;
border-radius: 10px;

}

.form{
margin-left: 100px;
margin-top: 140px;
width: 357px;
height: 92px;

}

.form-title{
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 24px;
display: flex;
align-items: center;
margin-top: 50px;
color: #483700;


}


.form-input form div [type="text"]{
margin-top: 10px;
box-sizing: border-box;
background-color: transparent;
width: 357px;
height: 40px;

border: 1px solid #483700;
border-radius: 10px;

}



.form-input form div [type="text"]:focus {
  outline: none !important;
    border:1px solid #483700;
}

.mint-button{
  font-size: 15px;
  color: #FFFFFF;
  font-weight: bold;
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  line-height: 50px;
  width: 202px;
  height: 54px;
  left: 100px;
  top: 750px;
  cursor: pointer;
  background: #483700;
  border: 3px solid #483700;
  border-radius: 10px;
}

.mint-button2{
  font-size: 15px;
  color: #FFFFFF;
  font-weight: bold;
  box-sizing: border-box;
  position: absolute;
  text-align: center;
  line-height: 50px;
  width: 202px;
  height: 54px;
  left: 315px;
  top: 750px;
  cursor: pointer;
  background: #483700;
  border: 3px solid #483700;
  border-radius: 10px;
}

.space{
  position: absolute;
  font-size: 15px;
  width: 202px;
  height: 54px;
  left: 350px;
  top: 920px;
}



.custom-video-input {
  color: transparent;
}
.custom-video-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-video-input::before {
  font-size: 15px;
  color: #483700;
  font-weight: bold;
  content: 'SELECT';
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  padding: 12px 69px 12px 69px;
  left: 100px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
}

.custom-video-input.uploaded::before {
  content: 'CHANGE';
}



.custom-video-input:hover::before {
  border-color: black;
}
.custom-video-input:active {
  outline: 0;
}
.custom-video-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}




.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  font-size: 15px;
  color: #483700;
  font-weight: bold;
  content: 'SELECT';
  box-sizing: border-box;
  text-align: center;
  line-height: 50px;
  padding: 12px 69px 12px 69px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
}

.custom-file-input.uploaded::before {
  content: 'CHANGE';
}



.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.custom-file-input3 {
  color: transparent;
}
.custom-file-input3::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input3::before {
  font-size: 15px;
  color: #483700;
  font-weight: bold;
  content: 'SELECT';
  box-sizing: border-box;
  text-align: center;
  line-height: 50px;
  padding: 12px 69px 12px 69px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
}

.custom-file-input3.uploaded::before {
  content: 'CHANGE';
}



.custom-file-input3:hover::before {
  border-color: black;
}
.custom-file-input3:active {
  outline: 0;
}
.custom-file-input3:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.custom-file-input4 {
  color: transparent;
}
.custom-file-input4::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input4::before {
  font-size: 15px;
  color: #483700;
  font-weight: bold;
  content: 'SELECT';
  box-sizing: border-box;
  padding: 12px 69px 12px 69px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
}

.custom-file-input4.uploaded::before {
  content: 'CHANGE';
}



.custom-file-input4:hover::before {
  border-color: black;
}
.custom-file-input4:active {
  outline: 0;
}
.custom-file-input4:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}

.rounded-image {
  height: 30vmin;
  border-radius: 25px;
}

.rounded-image2 {
  height: 400px;
  border-radius: 25px;
  margin-top: 50px;
}


.email-form {
  position: relative;
  background-color: #ffffff;
  padding: 6px;
  width: 550px;
  border: 2px solid #ffffff;
  border-radius: 50px; 
  text-align: left;
  margin-top: 20px;
}

.email-form input[type="email"] {
  width: 550px;
  padding: 14px 20px;
  font-size: 20px;
  background-color: transparent;
  box-sizing: border-box;
  border: transparent;
  border-radius: 10px;
  outline: none;
}

.email-form input[type="text"] {
  width: 550px;
  padding: 14px 20px;
  font-size: 20px;
  background-color: transparent;
  box-sizing: border-box;
  border: transparent;
  border-radius: 10px;
  outline: none;
}



.email-form button[type="submit"] {
  border-radius: 35px;
  border: 2px;
  background: linear-gradient(90deg, #5ec0ae 0%, #818cbd 100%);
  padding: 2vmin 2.5vmin;
  font-size: 16px;
  color: white;
  cursor: pointer;
  width: 150px;
  position: absolute;
  right: 1%;  
}

.email-form button:hover[type="submit"] {
  background: linear-gradient(270deg,  #9FACE6 100%, #74EBD5 0%);
  color: #313131;
}


.order-form {
  position: relative;
  background-color: #ffffff;
  padding: 6px;
  width: 550px;
  border: 2px solid #ffffff;
  border-radius: 50px; 
  text-align: left;
  margin-top: 20px;
}

.order-form input[type="email"] {
  width: 550px;
  padding: 14px 20px;
  font-size: 20px;
  background-color: transparent;
  box-sizing: border-box;
  border: transparent;
  border-radius: 10px;
  outline: none;
}

.order-form input[type="text"] {
  width: 550px;
  padding: 14px 20px;
  font-size: 20px;
  background-color: transparent;
  box-sizing: border-box;
  border: transparent;
  border-radius: 10px;
  outline: none;
}




.buttons{
  display: flex;
  flex-direction: row;
  margin-left: 100px;
  margin-top: 80px;
}


.button {
  height: 50px;
  box-sizing: border-box;
  margin-right: 20px;
  width: 202px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
  font-size: 15px;
  color: #483700;
  font-weight: bold;
}

.button2 {
  box-sizing: border-box;
  width: 202px;
  cursor: pointer;
  background: #483700;
  border: 3px solid #483700;
  border-radius: 10px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.button2:hover {
  box-sizing: border-box;
  width: 202px;
  cursor: pointer;
  background: #FFFFFF;
  border: 3px solid #483700;
  border-radius: 10px;
  color: #483700;
}
.button-link {
  text-decoration: none;
}

a.button2:hover {
  text-decoration: none;
}


.connect-button{

box-sizing: border-box;

position: absolute;
width: 202px;
height: 54px;
left: 1207px;
top: 18px;
cursor: pointer;
background: #FFFFFF;
border: 3px solid #483700;
border-radius: 10px;
}

.connect-text{


font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 18px;
align-items: center;
text-align: center;

color: #483700;
}


.button:hover {
  border-style: solid;
  background-color: #483700;
  color: white;
}

.connect-button:hover[type="submit"] {
  border-style: solid;
  border-color: black;
}

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3vmin;
  margin-right: 5vmin;
  margin-left: 5vmin;
}

.main-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3vmin;
  margin-right: 5vmin;
  margin-left: 5vmin;
}

.div-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.div-container2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}

@media only screen and (max-width: 800px) {
  .email-form {
    width: 300px;
    padding: 2px;

  }

  .email-form input[type="email"],
  .email-form input[type="text"] {
    width: 300px;
    font-size: 10px;
  }

  .email-form button[type="submit"] {
    width: 100px;
    font-size: 15px;
    padding: 2.8vmin 2.2vmin;
  }
  .order-form {
    width: 300px;
    padding: 2px;

  }

  .order-form input[type="email"],
  .order-form input[type="text"] {
    width: 300px;
    font-size: 10px;
  }

  .order-form button[type="submit"] {
    width: 100px;
    font-size: 15px;
    padding: 2.8vmin 2.2vmin;
  }

  .main-container {
    display: flex;
    flex-direction:column;
  }

  .main-container2 {
    display: flex;
    flex-direction:column;
  }
  .title {
    text-align: center;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 30px;
  }
  

  .App-logo {
    position: absolute;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 790px) and (max-width: 1200px)  {
  .email-form {
    width: 400px;
    padding: 2px;

  }

  .email-form input[type="email"],
  .email-form input[type="text"] {
    width: 400px;
    font-size: 10px;
  }

  .email-form button[type="submit"] {
    width: 150px;
    font-size: 15px;
    padding: 1.4vmin;
  }
  .order-form {
    width: 400px;
    padding: 2px;

  }

  .order-form input[type="email"],
  .order-form input[type="text"] {
    width: 400px;
    font-size: 10px;
  }

  .order-form button[type="submit"] {
    width: 150px;
    font-size: 15px;
    padding: 1.4vmin;
  }

  .main-container {
    display: flex;
    flex-direction:column;
  }

  .main-container2 {
    display: flex;
    flex-direction:column;
  }
  .title {
    text-align: center;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 30px;
  }
  

  .App-logo {
    position: absolute;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
  }
}
